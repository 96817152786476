export default function youtubeEmbed() {
    document.querySelectorAll('[data-youtube-id').forEach((embedNode) => {
        embedNode.addEventListener('click', e => {
            const videoId = embedNode.dataset.youtubeId;
            let videoNode = document.createElement("iframe");
            videoNode.src = `//www.youtube.com/embed/${videoId}?autoplay=1&html5=1&rel=0&autohide=0`;
            videoNode.setAttribute("frameborder", "0");
            videoNode.setAttribute("allow", "autoplay; fullscreen");
            videoNode.setAttribute("rel", 0)

            embedNode.parentNode.appendChild(videoNode);
            embedNode.classList.add("has-video");
            e.preventDefault();
        })
    });
}