function menuActions() {
  var $navTrigger = document.querySelector("[data-navigation-trigger]");
  var $navTarget = document.querySelector("[data-navigation-target]");
  if ($navTrigger && $navTarget) {
    var $navLinks = $navTarget.querySelectorAll("a");

    $navTrigger.addEventListener("click", e => {
      $navTrigger.classList.toggle("is-active");
      $navTarget.classList.toggle("is-active");
      document.body.classList.toggle("menu-open");

      e.preventDefault();
    });

    $navLinks.forEach($l => {
      $l.addEventListener("click", e => {
        $navTrigger.classList.toggle("is-active");
        $navTarget.classList.toggle("is-active");
      });
    });
  }

  let open = false;
  document.querySelectorAll('[data-site-search]').forEach($searchTrigger => {
    const $siteSearch = document.querySelector('.site-search')
    $searchTrigger.addEventListener('click', (e) => {
      e.preventDefault();
      open = !open;
      $siteSearch.classList.toggle('is-active');
      $searchTrigger.classList.toggle('is-active');
      if (open) {
        $siteSearch.querySelector('input[type="text"]').focus();
      }
    });
  });
}

export default menuActions;
