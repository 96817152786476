export default function vimeoEmbed() {
    document.querySelectorAll('[data-vimeo-id').forEach((embedNode) => {
        embedNode.addEventListener('click', e => {
            const videoId = embedNode.dataset.vimeoId;
            let videoNode = document.createElement("iframe");
            videoNode.src = `//player.vimeo.com/video/${videoId}`;
            videoNode.setAttribute("frameborder", "0");
            videoNode.setAttribute("allow", "autoplay; fullscreen");
            videoNode.setAttribute("rel", 0)

            embedNode.parentNode.appendChild(videoNode);
            embedNode.classList.add("has-video");
            e.preventDefault();
        })
    });
}