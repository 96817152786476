export class BookSwiper {
	constructor($container, swiper) {
		this.$books = $container.querySelectorAll(".book-card");
		this.$activeBook = null;
		this.swiper = swiper;
		this.$container = $container;
		this.running = false;
	}

	activate() {
		this.$books.forEach($b => {
			let $thumb = $b.querySelector(".book-card__thumb");
			let $thumbImg = $thumb.querySelector("img");
			let $close = $b.querySelector(".book-card__meta-close");

			let setRemainingHeight = _ => {
				$b.style.setProperty(
					"--remaining-height",
					`${$b.offsetHeight - $thumb.offsetHeight}px`
				);
			};

			if ($thumbImg.complete) {
				setRemainingHeight();
			} else {
				$thumbImg.onload = setRemainingHeight;
			}

			$thumb.addEventListener("click", this.open.bind(this, $b));
			$close.addEventListener("click", this.close.bind(this, $b));

			// TODO: How can we achieve the same thing using touch events? Test ontouchstart.
			$thumb.addEventListener(
				"mouseenter",
				this.updateBookAlignment.bind(this, $b, $thumb)
			);
		});

		this.running = true;
	}

	deactivate() {
		this.running = false;
	}

	open($book, event) {
		if ($book.classList.contains("is-active")) {
			$this.addEventListener("click", this.open.bind(this, $b));
		}

		if (this.running) {
			if (this.$activeBook) {	
				this.$activeBook.classList.remove("is-active");
			}

			this.swiper.detachEvents();

			this.$container.classList.add("has-open-book");
			$book.classList.add("is-active");
			this.$activeBook = $book;

			event.preventDefault();
		}
	}

	close($book, event) {
		if (this.running) {
			this.$container.classList.remove("has-open-book");
			$book.classList.remove("is-active");

			this.swiper.attachEvents();

			event.preventDefault();
		}
	}

	updateBookAlignment($book, $thumb, event) {
		if (this.running) {
			let bookBbox = $thumb.getBoundingClientRect();
			if (bookBbox.right < window.innerWidth / 2) {
				$book.classList.add("is-left");
				$book.classList.remove("is-right");
			} else {
				$book.classList.add("is-right");
				$book.classList.remove("is-left");
			}

			event.preventDefault();
		}
	}
}
